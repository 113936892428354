import { DatePicker } from "antd";
import { rangePresets } from "./charts/mock/chartsMock";
import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { dayjsCST } from "../../../utils/dayjsConfig";

interface OwnProps {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  setSelectedStartDate: Dispatch<SetStateAction<number>>;
  setSelectedEndDate: Dispatch<SetStateAction<number>>;
  restrictToOneDay?: boolean;
}

const { RangePicker } = DatePicker;

export const CustomRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setSelectedStartDate,
  setSelectedEndDate,
  restrictToOneDay,
}: OwnProps) => {
  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      setStartDate(dayjsCST(dates[0]).format());
      setEndDate(dayjsCST(dates[1]).endOf('d').format());
      setSelectedStartDate(dayjsCST(dates[0]).unix());
      setSelectedEndDate(dayjsCST(dates[1]).endOf('d').unix());
    } else {
      console.log("Clear");
    }
  };

  const onDateChange = (date: Dayjs | null) => {
    if (date) {
      // Set the start and end dates to the beginning and end of the selected day
      const selectedDateStart = dayjsCST(date).startOf('day').format();
      const selectedDateEnd = dayjsCST(date).endOf('day').format();

      setStartDate(selectedDateStart);
      setEndDate(selectedDateEnd);
      setSelectedStartDate(dayjsCST(selectedDateStart).unix());
      setSelectedEndDate(dayjsCST(selectedDateEnd).unix());
    } else {
      console.log('Clear');
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjsCST().add(-7, "hour").endOf("d");
  };

  return (
    <>
      {!restrictToOneDay && (
        <RangePicker
          presets={rangePresets}
          format="MM/DD/YYYY"
          onChange={onRangeChange}
          style={{ width: "100%" }}
          defaultValue={[dayjsCST(startDate), dayjsCST(endDate)]}
          disabledDate={disabledDate}
        />
      )}
      {restrictToOneDay && (
        <DatePicker
          format="MM/DD/YYYY"
          onChange={onDateChange}
          style={{ width: '100%', marginLeft: '8px' }}
          disabledDate={disabledDate}
          value={dayjsCST(endDate)} // Display the selected date
        />
      )}
    </>
  );
};
