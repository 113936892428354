import { PageContent } from "../styled";
import { Tabs, TabsProps, Typography } from "antd";
import { BayUtilizationChart } from "./components/charts/BayUtilizationChart";
import { useTrackOffset } from "../../hooks/trackOffsetHook";
import { useState } from "react";
import { BayUtilizationHeatmapChart } from "./components/charts/BayUtilizationHeatmapChart";
import { dayjsCST } from "../../utils/dayjsConfig";

export const ReportsPage = () => {
  const { containerRef, containerHeight } = useTrackOffset();
  const [startDate, setStartDate] = useState(
    dayjsCST().add(0, "day").startOf("d").format(),
  );
  const [endDate, setEndDate] = useState(dayjsCST().format());
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjsCST().add(0, "day").startOf("d").unix(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(dayjsCST().endOf("d").unix());
  console.log('selectedEndDate:', selectedEndDate)
  const [filtersSubmitted, setFiltersSubmitted] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Bay Utilization Heatmap",
      children: (
        <BayUtilizationHeatmapChart
          chartHeight={containerHeight - 250}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filtersSubmitted={filtersSubmitted}
          setFiltersSubmitted={setFiltersSubmitted}
        />
      ),
   },
    {
      key: "2",
      label: "Bay Utilization",
      children: (
        <BayUtilizationChart
          chartHeight={containerHeight - 250}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filtersSubmitted={filtersSubmitted}
          setFiltersSubmitted={setFiltersSubmitted}
        />
      ),
    },
    // {
    //   key: "3",
    //   label: "Bay Occupancy by Unit Type",
    //   children: (
    //     <MaintenanceByUnitTypeChart
    //       chartHeight={containerHeight - 330}
    //       startDate={startDate}
    //       setStartDate={setStartDate}
    //       endDate={endDate}
    //       setEndDate={setEndDate}
    //       selectedStartDate={selectedStartDate}
    //       setSelectedStartDate={setSelectedStartDate}
    //       selectedEndDate={selectedEndDate}
    //       setSelectedEndDate={setSelectedEndDate}
    //       filtersSubmitted={filtersSubmitted}
    //       setFiltersSubmitted={setFiltersSubmitted}
    //     />
    //   ),
    // },
    // {
    //   key: "4",
    //   label: "Bay Vehicle Count",
    //   children: (
    //     <EfficiencyChart
    //       chartHeight={containerHeight - 330}
    //       startDate={startDate}
    //       setStartDate={setStartDate}
    //       endDate={endDate}
    //       setEndDate={setEndDate}
    //       selectedStartDate={selectedStartDate}
    //       setSelectedStartDate={setSelectedStartDate}
    //       selectedEndDate={selectedEndDate}
    //       setSelectedEndDate={setSelectedEndDate}
    //       filtersSubmitted={filtersSubmitted}
    //       setFiltersSubmitted={setFiltersSubmitted}
    //     />
    //   ),
    // },
    // {
    //   key: "5",
    //   label: "Maintenance Backlog",
    //   children: <MaintenanceBacklogChart chartHeight={containerHeight - 330} />,
    // },
];

  return (
    <PageContent ref={containerRef}>
      <Typography.Title
        style={{ margin: 0, fontWeight: "bold" }}
        level={3}
      >
        Reports
      </Typography.Title>
      <div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </PageContent>
  );
};
